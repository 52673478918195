import React from 'react'
import AlternateLanding from '../components/AlternateLanding/AlternateLanding'
import ContactDiv from '../components/Contact/Contact'
function Contact() {
    window.onload= window.scrollTo(0,0);
    return (
        <div>
            <div className="EmptyPadding"></div>
           <AlternateLanding title="Contact Us" sectionName="contactPage"/>  
           <ContactDiv/>
        </div>
    )
}

export default Contact
