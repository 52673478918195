import React from 'react'
import Heading from '../Heading/Heading'
import './Contact.css'
import HomeIcon from'../../images/home.png'
import PhoneIcon from'../../images/phone.png'
import MailIcon from'../../images/email.png'
function Contact() {
    return (
        <div className="contactSection h-fscreen">
            <div className="mapContainer">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230.24638433922317!2d88.48845751412823!3d22.58126690546565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a020bcc52336b45%3A0x2a992453184374a7!2sTHE%20MAD%20KNIFE!5e0!3m2!1sen!2sin!4v1639311863441!5m2!1sen!2sin"  allowfullscreen="" loading="lazy"></iframe>
            </div>
            
            <div className="contactDetailsWrapper">
                <div className="contactForm col-md-12 col-lg-8">
                    <div className="contactHeading">Get in touch</div>

                    <form action="#" className="gform contactForm">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                        <label for="Name">Your Name</label>
                        <input type="text" className=" contactInput" id="Name" placeholder="E.g. John Doe"/>
                        </div>
                        <div className="form-group col-md-6">
                        <label for="Name">Your Email</label>
                        <input type="email" className=" contactInput" id="Email" placeholder="E.g. Johndoe@email.com"/>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-12">
                        <label for="Message">Your Message Here</label>
                        <textarea  className=" contactInput" id="Message" placeholder="Your Message Here"/>
                        </div>
                    </div>
                    
                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <input type="submit" value="Submit" className="submitBtn" />
                        </div>
                    </div>
                    </form>
                </div>
                <div className="contactDetails col-md-12 col-lg-3">
                    <div className="contactDetailDiv">
                        <img src={HomeIcon} alt=""  />
                        <div className="contactInfoDiv">
                            <span>Newton, Kolkata</span>
                            <span>Opposite TCS Gitanjali Park,</span><span>Action area II</span>
                        </div>
                    </div>
                    <div className="contactDetailDiv">
                        <img src={PhoneIcon} alt="" />
                        <div className="contactInfoDiv">
                            <span>+91 91239 14079</span>
                            <span>Everyday 10am - 8pm</span>
                        </div>
                    </div>
                    <div className="contactDetailDiv">
                        <img src={MailIcon} alt="" />
                        <div className="contactInfoDiv">
                            <span>hello@madknife.com</span>
                            <span>24*7 availabe</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
