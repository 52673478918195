
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Nav from './components/nav/Nav';
import Home from './pages/Home'
import About from './pages/About';
import Footer from './components/Footer/Footer'
import Menu from './pages/Menu';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
function App() {
  return (
    <>
    <Router>
      <Nav/> 
      <Switch>
      <Route path="/" exact component={Home}/>
      <Route path="/ourvalues"  component={About}/>
      <Route path="/menu"  component={Menu}/>
      <Route path="/gallery"  component={Gallery}/>
      <Route path="/contact"  component={Contact}/>
      
      </Switch>
      <Footer/>
      </Router>
    </>
  );
}

export default App;
