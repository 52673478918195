import React,{useEffect,useState} from 'react'
import './Menu.css'
import Heading from '../Heading/Heading'
import TypographyImage from '../../images/typography.png'
import TypographyImage1 from '../../images/typography1.png'
import MenuJson from '../../menu.json'
function Menu(props) {
    const [menu, setMenu] = useState([]);
    const [counter, setcounter] = useState(1);
    useEffect(() => {
        fetch('https://cascading-ninja-youtube.github.io/mad-knife-menu/menu.json')
        .then(res=>res.json())
        .then(data=>{
            setMenu(data) 
             console.log(menu);
             if(menu.length<1)
             setcounter(counter+1)
            })
      }, [counter])
    return (
        <section className="MenuWrapper">
            <div className="headingPadding">
          <Heading smallText="Popular Menu" bigText="Our Delicious Recipes"/>
          </div>
          <div className="menuCardWrapper">
              {MenuJson.filter(item=>item.id<=props.count).map(item=>
                (
                    <div className="menuCard col-sm-5 col-lg-3 col-xs-10">
                    <div className="menuImage">
                    <img src={item.image} alt="" />
                    
                    </div>
                    <div className="menuCardDetails CardDetails">
                        <div className="menuCardName column center">
                            <h3 className="menuRecipeName">{item.recipeName}</h3>
                            <p className="menuRecipeServing">
                                
                                {item.veg?<span style={{color:'green'}}>{item.servings}</span>:<span style={{color:"var(--secondary-color)"}}>{item.servings}</span>}
                                
                                
                                </p>
                            
                        {/* <div className="menuCardpara menuCardPrice">₹ 169 </div> */}
                        </div>
                        
                    
                    </div>
                    <img src={TypographyImage} alt="" className="typographyImage"/>

            </div>
                )
                )}
               

          </div>
        </section>
    )
}

export default Menu
