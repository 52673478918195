import React,{useState,useEffect} from 'react'
import { NavLink,Link } from 'react-router-dom'
import './Nav.css'
import LogoBlack from '../../images/LogoBlack.png'
import DarkModeToggler from '../DarkModeToggler/DarkModeToggler';
function Nav(props) {
    const [open, setOpen] = useState(false);
    function changeNav()
    {
        open?closeNav():openNav()
        setOpen(!open)
    }
    function openNav()
    {
        document.getElementById('navMenu').classList.add('open')
    }
    function closeNav()
    {
        document.getElementById('navMenu').classList.remove('open')
    }
    function borderNav() {
        if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
          document.getElementById("navBar").style.boxShadow = " rgba(0, 0, 0, 0.24) 0px 3px 8px";
        //   document.getElementById("navBar").style.position = "fixed";
          document.getElementById("navBar").style.background = "var(--primary-color)";
          document.getElementById("navBar").style.color = "var(--dark-text)";
        
   
         
        } else {
          document.getElementById("navBar").style.boxShadow = " transparent 0px 3px 8px";
        //   document.getElementById("navBar").style.position = "relative";
        //   document.getElementById("navBar").style.background = "transparent";
          document.getElementById("navBar").style.color = "var(--dark-text)";
        
      
       
        }
      }

      window.onscroll= borderNav;

     useEffect(() => {
        if(window.location.pathname.split('/')[1]!="")
        document.getElementById('navBar').style.background="var(--primary-color)"
      
     }, [window.location.pathname]);

    return (
        <section className="Nav" id="navBar">
            <Link to="/" className="navLogo"><img src={LogoBlack} alt="" id="navLogo" /></Link>
            <div className="navMenu" id="navMenu">
                <div id="closeBtn" onClick={()=>changeNav()}>&times;</div>
                <NavLink className="menuItem" to="/" exact activeClassName="active" onClick={()=>closeNav()}>   
                    Home
                </NavLink>
                <NavLink className="menuItem" to="/ourvalues" activeClassName="active" onClick={()=>closeNav()}>   
                    Our Values
                </NavLink>
                <NavLink className="menuItem" to="/menu" activeClassName="active" onClick={()=>closeNav()}>   
                    Menu
                </NavLink>
                <NavLink className="menuItem" to="/gallery" activeClassName="active" onClick={()=>closeNav()}>   
                   Gallery
                </NavLink>
                {/* <NavLink className="menuItem" to="/blog" activeClassName="active" onClick={()=>closeNav()}>   
                    Blogs
                </NavLink> */}
                <NavLink className="menuItem" to="/contact" activeClassName="active" onClick={()=>closeNav()}>   
                    Contact
                </NavLink>
                <div className="navSocials">
                    
                </div>
            </div>
            <div className="rightMenu">
            <div className="hamBurgerMenu" onClick={()=>changeNav()}>
                <span id="_1"></span>
                <span id="_2"></span>
                <span id="_3"></span>
                </div>
            <DarkModeToggler/>
            </div>
        </section>
    )
}

export default Nav
