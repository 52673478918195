import React from 'react'
import AlternateLanding from '../components/AlternateLanding/AlternateLanding'
import MenuDiv from '../components/Menu/Menu'
function Menu() {
    window.onload= window.scrollTo(0,0);
    return (
        <div>
            <div className="EmptyPadding"></div>
             <AlternateLanding title="Our Menu" sectionName="MenuPage"/>
             <MenuDiv count={50}/>
        </div>
    )
}

export default Menu
