import React from 'react'
import AboutDiv from '../components/About/About'
import AlternateLanding from '../components/AlternateLanding/AlternateLanding'
import Team from '../components/Team/Team'
import VideoWrapper from '../components/VideoWrapper/VideoWrapper'
function About() {
    window.onload= window.scrollTo(0,0);
    return (
        <div>
            <div className="EmptyPadding"></div>
            <AlternateLanding title="About Us" sectionName="AboutPage"/>
            <AboutDiv page="About"/>
       
        </div>
    )
}

export default About
