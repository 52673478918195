import React from 'react'
import About from '../components/About/About'
import Landing from '../components/Landing/Landing'
import Menu from '../components/Menu/Menu'
import FoundersMessage from '../components/FounderMessage/FounderMessage'
import Team from '../components/Team/Team'
import VideoWrapper from '../components/VideoWrapper/VideoWrapper'

function Home() {
  window.onload= window.scrollTo(0,0);
    return (
        <>
      <Landing/>
      <About page="Home"/>
      {/* <VideoWrapper/> */}
      {/* <Team/> */}
      <Menu count={3}/>
      <FoundersMessage/>
        </>
    )
}

export default Home
