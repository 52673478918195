import React,{useState, useEffect} from 'react'
import './Footer.css'
import LogoIcon from '../../images/logoWhite.png'

function Footer() {
    const [year, setYear] = useState('2021');
    useEffect(() => {
        var d= new Date()
        setYear(d.getFullYear())
    }, []);
    return (
        <section className="" style={{minHeight:"60vh"}}>
        <section className="Footer">
            <div className="FooterTopBar" >
                <img src={LogoIcon} alt="Pulao roast logo" className="footerLogo" />
                <div className="footerSocials">
                    <span className="serif">Follow Us on: </span>
                    <a href="https://www.facebook.com/themadknife" target="_blank" rel="noopener noreferrer" className="content"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                    <a href="#" target="_blank" rel="noopener noreferrer" className="content"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                    <a href="#" target="_blank" rel="noopener noreferrer" className="content"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                    <a href="#" target="_blank" rel="noopener noreferrer" className="content"><i className="fa fa-youtube-play" aria-hidden="true"></i></a>
                </div>
            </div>
            <div className="FooterContent row">
                <div className="col-lg-3 col-md-5">
                    <h2 className="FooterConentTitle serif">About Us</h2>
                    <div className="FooterContentPara">The Mad knife was born of the radical belief that there is a correlation between how food is grown and processed, and how it tastes. We have committed ourselves to serving food with integrity and revolutionizing the fast-food industry.</div>
                </div>
                <div className="col-lg-3 col-md-5">
                    <h2 className="FooterConentTitle serif">Contact Us</h2>
                    <div className="FooterContentContact">
                        <div className="FooterContact">
                            <span>Call</span>
                            <span><a href="#">+91 9123914079</a> </span>
                        </div>
                        <div className="FooterContact">
                            <span>Write</span>
                            <span><a href="#">info@domain.com</a> </span>
                        </div>
                        <div className="FooterContact footerAddress">
                            <span>Find Us:</span>
                            <span id="footerAddress"><a href="#">Opposite TCS Gitanjali Park, <br />Action Area II, Newtown</a> </span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-12">
                <h2 className="FooterConentTitle serif">Subscribe</h2>
                <div className="FooterContentPara">Want to be notified when we launch a new dish or an udpate. Just sign up and we'll send you a notification by email.</div>
                <form action="https://script.google.com/macros/s/AKfycbyZmbl_KFCxUorl0PBHt2X8rnu-laKMIeKD2aQSDp-8n6m3AmDwyirSp51ghQxb_vvoew/exec" id="newsletterForm" className="gform" method="POST" onSubmit={(e)=>e.preventDefault()}>
                    <input type="email" placeholder="Enter Email" className="content" name="Email" required/>
                    <button type="submit" className="serif content">Send</button>
                    <div className="alertdemo"></div>
                </form>
                </div>

                
            </div>
        </section>
            <div className="copyright center column">
          <span>© Mad Knife {year}</span> <span>  Powered by <a href="https://www.cascadingninja.com/" target="_blank" rel="noopener noreferrer"> &nbsp;Cascading Ninja.</a></span> <span> All rights reserved.</span>
            </div>
            </section>
    )
}

export default Footer
