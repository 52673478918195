import React from 'react'
import './Landing.css'
import LeftIcon from '../../images/left_1.svg'
import play from '../../images/play.svg'
import { Link } from 'react-router-dom'
function Landing() {
    return (
        <section className="landingSection h-fscreen center">
            <div className="landingDetails col-lg-6">

                <div className="landingTitle secondaryColor">The Snack Madness</div>
                <div className="landingHeading">Welcome to the Mad Knife</div>
                <div className="landingPara">It's quite true to say that if you work with marvellous fresh ingredients, the simplest dish is taken to another level. We invest in our planet. We source only the best ingredients to deliver an incredible culinary experience </div>
                <div className="landingBtnWrapper center">
                <a href="https://orders.themadknife.com" target="_blank" rel="noopener noreferrer" className="primaryButton landingButton ">
                       Order Now
                        <div className="primaryButtonIconBefore"></div>
                        <img src={LeftIcon} alt="" className="primaryButtonIcon" id="landingBtnIcon"/>
                    </a>
          
                    <div className="landingvideoBtn center ">
                        <div id="playBtn">
                        <img src={play} alt="" />
                        </div>
                        Watch our story
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Landing
