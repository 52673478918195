import React from 'react'
import Heading from '../Heading/Heading'
import './About.css'
import AboutImg from '../../images/who_are_we.jpeg'
import Mission from '../../images/mission.jpeg'
import Folds from '../../images/png/CrunchyChickenCheeseFolds.png'
import CreepyFolds from '../../images/png/CrispyCreepyFolds.png'
import LeftIcon from '../../images/left_1.svg'
import { Link } from 'react-router-dom'
function About(props) {
    return (
        <>
        <section className="AboutSection ">
            <div className="col-md-5 col-xs-12 aboutImage">
                <img src={AboutImg} alt="" />             

            </div>
            <div className="col-md-5 col-xs-12 aboutDetails">
            {/* <Heading smallText="Who are we" bigText="Where The Food’s As Good As The Root Beer."/> */}
            <div className="aboutHeadings"> Who are we </div>
            <h5 className="secondaryColor aboutsecondary">Satisfying people's hunger for simple pleasures</h5>
            <p className="aboutPara">Cooking at home with fresh ingredients means you know what’s going into your food. At Mad Knife, we’re all about serving up fresh food, even if it means going the extra mile. When you walk through our doors, we do what we can to make everyone feel at home and let you experience the high global standards in terms of taste & hygiene because our family extends through your community.  </p>
            {props.page=="Home"?<>
            <Link to="/ourvalues" className="primaryButton  ">
                       Read More
                        <div className="primaryButtonIconBefore"></div>
                        <img src={LeftIcon} alt="" className="primaryButtonIcon" />
                    </Link>
           
            </>:
            
            <>
             <div className="aboutHeadings"> Our Values </div>
            <h5 className="secondaryColor aboutsecondary">"Its simple, great ingredient brings excellent taste. 
And the secret ingredient is always fresh ingredients." </h5>
            <p className="aboutPara">Its simple, great ingredient brings excellent taste. 
            And the secret ingredient is always fresh ingredients. 
            The intention behind The Mad Knife is straightforward: food served fast doesn’t have to be “fast food". Applying high-quality, wholesome ingredients, classically cooked, with zero added artificial colours, flavours, or preservatives with an interactive service format produces extraordinary results. We are passionate about servicing a great guest experience and making our food more accessible to everyone. We strive to make delicious, actual food accessible to all in a welcoming and engaging environment.</p>
           
            </> }
            </div>
        </section>
        {props.page=="About"?
        <>        
                <section className="AboutSection " style={{flexDirection:"row-reverse"}}>
                    <div className="col-md-5 col-xs-12 aboutImage">
                        <img src={Mission} alt="" />             

                    </div>
                
                    <div className="col-md-5 col-xs-12 aboutDetails">
                    <div className="aboutHeadings"> Our Mission </div>
                    {/* <h5 className="secondaryColor">Satisfying people hunger for simple pleasures</h5> */}
                    <p className="aboutPara">The Mad knife was born of the radical belief that there is a correlation between how food is grown and processed, and how it tastes. We have committed ourselves to serving food with integrity and revolutionizing the fast-food industry. Our original elements are responsibly sourced and classically cooked with people, animals, and the habitat in mind. Everything is related. Something will never change and that’s innovation. We know nothing beats the value of hard work. It’s what goes into every good old-fashioned meal we create. Original is better. Better for You, Better for People, Better for Our Planet. It may be the hard way to do things, but it’s the right way. We commit The Mad knife to holding at what’s right because Times change but values don’t.</p>
                    <div className="aboutHeadings"> Our Goals </div>
                    {/* <h5 className="secondaryColor">Satisfying people hunger for simple pleasures</h5> */}
                    <p className="aboutPara">Sustainability is and always will be a strategic priority for THE MAD KNIFE. 
                    Our future goal is to always achieve sustainability by reducing the level of carbon emission into the atmosphere and adaptation of more eco-friendly bio-degradable packaging methods. 

                    Support zero food wastage through helping below poverty line segments by clean food distributional method and supporting a noble cause. 

                    Increase the percentage of renewable energy sources year after year.

                    Pilot at least one innovative new packaging design that reduces plastic every year.</p>         
                    </div>
                </section>
                
                </>
        :<></>}
      
        </>
    )
}

export default About
