import React,{useEffect,useState} from 'react'
import './Gallery.css'
import GalleryList from '../../gallery.json'

function Gallery() {
    const [gallery, setGallery] = useState([]);
    const [counter, setcounter] = useState(1);
    useEffect(() => {
        fetch('https://cascading-ninja-youtube.github.io/mad-knife-menu/gallery.json')
        .then(res=>res.json())
        .then(data=>{
            setGallery(data) 
             console.log(gallery);
             if(gallery.length<1)
             setcounter(counter+1)
            })
      }, [counter])
    return (
        <div className="gallerySection h-fscreen">
            <div className="gallery-image row">
                {gallery.map(item=>(

                    <div className="img-box col-lg-3 col-md-6 col-sm-12" key={item.id}>
                    <img src={`https://cascading-ninja-youtube.github.io/mad-knife-menu/gallery/${item.image}`} alt="" />
                    <div className="transparent-box">
                        <div className="caption">
                        <p>{item.firstLine}</p>
                        <p className="opacity-low">{item.secondLine}</p>
                        </div>
                    </div> 
                    </div>
                ))}
                  
                   
            </div>
        </div>
    )
}

export default Gallery
