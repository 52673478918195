import React,{useState,useEffect} from 'react'
import './DarkModeToggler.css'
import LogoWhite from '../../images/logoWhite.png'
import Logoblack from '../../images/LogoBlack.png'
import Sun from '../../images/sun.png'
import Moon from '../../images/moon.png'
function DarkModeToggler() {

    const [dark, setDark] = useState(true);

    useEffect(() => {
        if(!dark)
        {
            // document.getElementById('togglerIcon').classList.add('fa-sun-o')
            // document.getElementById('togglerIcon').classList.remove('fa-moon-o')
            document.querySelector(':root').style.setProperty('--primary-color','#fff' ) 
           document.querySelector(':root').style.setProperty('--white-text','#fff' ) 
           document.querySelector(':root').style.setProperty('--dark-text','#111' )
           document.querySelector(':root').style.setProperty('--logo-filter','brightness(100%)' )
           document.querySelector(':root').style.setProperty('--signature-filter','brightness(0)' )
           document.querySelector(':root').style.setProperty('--box-shadow',' rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px' )
           document.querySelector(':root').style.setProperty('--contact-icon',' brightness(40%)' )
           document.querySelector(':root').style.setProperty('--about-gray',' rgb(80, 79, 79)' )
           document.querySelector(':root').style.setProperty('--founder-gray','#333')
           
           //    document.querySelector(':root').style.setProperty(' --about-gray','rgb(197, 194, 194)' )
           
           document.getElementById('navLogo').src=`${Logoblack}`
           document.getElementById('togglerIcon').src=`${Sun}`
           document.getElementById('togglerIcon').classList.remove('moon')
           
        }
        else
        {
            // document.getElementById('togglerIcon').classList.remove('fa-sun-o')
            // document.getElementById('togglerIcon').classList.add('fa-moon-o')
            document.querySelector(':root').style.setProperty('--primary-color','#111' ) 
            document.querySelector(':root').style.setProperty('--white-text','#111' ) 
            document.querySelector(':root').style.setProperty('--dark-text','#fff' )
            document.querySelector(':root').style.setProperty('--logo-filter','brightness(1200%)')
            document.querySelector(':root').style.setProperty('--signature-filter','brightness(100%)' )
            document.querySelector(':root').style.setProperty('--box-shadow','rgba(255, 255, 255, 0.1) 0px 4px 12px;' )
            document.querySelector(':root').style.setProperty('--contact-icon','brightness(100%)')
            document.querySelector(':root').style.setProperty('--about-gray','rgb(197, 194, 194)')
            document.querySelector(':root').style.setProperty('--founder-gray','rgb(207, 204, 204)')
            document.getElementById('navLogo').src=`${LogoWhite}`
            document.getElementById('togglerIcon').src=`${Moon}`
            document.getElementById('togglerIcon').classList.add('moon')
        }
       
    }, [dark])
    return (
      
            <div className="lightDarkToggler" id="lightDarkToggler" onClick={()=>setDark(!dark)}>
                {/* <i className="fa fa-sun-o" aria-hidden="true" id="togglerIcon"></i> */}
                <img src={Sun} alt="" id="togglerIcon" />
            </div>
      
    )
}

export default DarkModeToggler
