import React from 'react'
import AlternateLanding from '../components/AlternateLanding/AlternateLanding'
import GalleryDiv from '../components/Gallery/Gallery'
function Gallery() {
    window.onload= window.scrollTo(0,0);
    return (
        <div>
            <div className="EmptyPadding"></div>
            <AlternateLanding title="Our Gallery" sectionName="galleryPage"/>  
            <GalleryDiv/>
        </div>
    )
}

export default Gallery
