import React from 'react'
import './AlternateLanding.css'
function AlternateLanding(props) {
    return (
        <div className={`AlternateLanding center ${props.sectionName}`}>
            <h3 className="alternateLandingTitle">{props.title}</h3>
        </div>
    )
}

export default AlternateLanding
