import React from 'react'
import './FounderMessage.css'
import Heading from '../Heading/Heading'
import signature from '../../images/signature.png'
import founder from '../../images/founder.jpg'
function FounderMessage() {
    return (
        <div className="founderMessageWrapper">
            <div className="founderMessage col-md-6">
                <Heading smallText="Founder's Message" bigText='" Our mission is to win today while creating a bright future. "'/>
                <img src={founder} alt="" id="phoneImage"/>
                <p className="founderPara">
                I, along with the rest of the organization, strongly believe that how we grow our food is how we grow our future. We are a brand with a demonstrated purpose of Cultivating A Better World. Our mission is to win today while creating a bright future. 
                </p>
                <p className="founderPara">Cultivating A Better World is difficult, but when we all do our part, we can make a difference. As we continue to have the privilege of offering genuine food for our communities, our goal is to keep everyone safe and informed, since nothing is more important to us than our employees and our guests. Our people are the core of who we are and what we stand for. Our team members are at the forefront of our decisions, all the while encouraging hope, optimism, affirmation, and ongoing learning.</p>
                <p className="founderPara">Making the brand more visible and loved; Utilising a disciplined approach to creativity and innovation; Leveraging digital capabilities to drive productivity and expand access. Running successful outlets with a strong culture that provides great food with integrity while delivering exceptional in-outlet and digital experiences. </p>
                <p className="founderPara">
                Our relentless pursuit of ethical choices at all times is what we believe will build trust in us from our customers, business partners, colleagues, and investors. Every person in the organization maintains trust, both internally and externally. Responsible business does not exist without accountability. We hold ourselves accountable for the quality sourcing of ingredients in strict accordance with our industry. I commit us to transparently communicating our goals, progress, and strengthening priorities as we grow.
                The mad knife first launched with an unwavering commitment to serve fresh food, at a fair price, in a comfortable atmosphere. Doing the right thing, in the right way, is still what guides the company and our approach to corporate responsibility. 
                </p>
                <div className="founderSignature">
                <img src={signature} alt="" />
                <div className="designationDiv">
                    <div className="name">Sagor Biswas</div>
                    <div className="designation">Founder & CEO, The Mad Knife</div>
                </div>
                </div>
            </div>
            <div className="founderImage col-md-4">
                <img src={founder} alt="" />
            </div>
        </div>
    )
}

export default FounderMessage
