import React from 'react'
import './Heading.css'
function Heading(props) {
    return (
        <div className="Heading">
            <div className="smallText">{props.smallText}</div>
            <h2 className="bigText">{props.bigText}</h2>
        </div>
    )
}

export default Heading
